import React, { Component } from 'react'
import Button from './Button'
import client from '../client/client'

import './CartShipping.css'

// ORDER TYPE
const UNKNOWN = -1
const LOCAL = 0

class CartShipping extends Component {
  constructor(props) {
    super(props)
    this.state = {
      shipping: UNKNOWN,
      foreignShown: false,
      firstName: '',
      lastName: '',
      email: '',
      country: '',
      city: '',
      address: '',
      phone: '',
      discountCode: '',
      discount: 0,
    }
  }

  getProducts() {
    return this.props.cart.products.map((p) => {
      return {
        pid: p.id,
        size: p.size,
        quantity: p.qty,
      }
    })
  }

  getTotal() {
    if (this.props.cart.products.length === 0) {
      return 0
    }

    const productsPrice = this.props.cart.products
      .map(p => p.price)
      .reduce((a, c) => a + c)

    let total = productsPrice
    if (this.state.discount > 0) {
      total -= total * (this.state.discount / 100)
    }

    return total.toFixed(2)
  }

  getLocalOrder() {
    return {
      order_type: LOCAL,
      customer: {
        fname: this.state.firstName,
        lname: this.state.lastName,
        email: this.state.email,
        phone: this.state.phone,
      },
      shipping: {
        city: this.state.city,
        addr1: this.state.address,
        additional_info: this.state.discountCode,
      },
      products: this.getProducts(),
      total: this.getTotal(),
    };
  }

  validateInput() {
    return this.getTotal() > 0 &&
      this.state.firstName.length > 0 &&
      this.state.lastName.length > 0 &&
      this.state.email.length > 0 &&
      this.state.city.length > 0 &&
      this.state.address.length > 0 &&
      this.state.phone.length > 0
  }

  async onDiscountChange(code) {
    this.setState({ discountCode: code})

    if (code.length >= 8) {
      const resp = await client.get(`/discount/${code}`)
      this.setState({ discount: resp.discount })
    }
  }

  async onLocalCheckout() {
    if (!this.validateInput()) {
      console.error('Failed to validate input')
      return
    }

    this.props.toggleLoader()

    const order = this.getLocalOrder()
    console.log(order)

    try {
      const resp = await client.post('/purchase', order)
      if (resp.ok) {
        this.props.cart.clear()
        this.props.toggleLoader()

        window.fbq('track', 'Purchase', {
          currency: "EUR",
          value: order.total,
        });

        window.location.href = '/purchase'
      }
    } catch (e) {
      console.error(e)
      // show notification here
    }
  }

  shippingDestination() {
    return (
      <div className='shipping-wrapper'>
        <div className='shipping-btn-wrapper'>
          <Button
            className='shipping-btn'
            value={'BULGARIA'}
            onClick={() => {
              this.setState({
                shipping: LOCAL
              })
          }}/>
        </div>
      </div>
    )
  }

  shippingLocal() {
    const fn = this.state.firstName.length > 0 ? 'input' : 'input invalid';
    const ln = this.state.lastName.length > 0 ? 'input' : 'input invalid';
    const email = this.state.email.length > 0 ? 'input' : 'input invalid';
    const city = this.state.city.length > 0 ? 'input' : 'input invalid';
    const addr = this.state.address.length > 0 ? 'input' : 'input invalid';
    const phone = this.state.phone.length > 0 ? 'input' : 'input invalid';
    return (
      <div>
        <input className={fn}
          value={this.state.firstName}
          onChange={(e) => { this.setState({ firstName: e.target.value}) }}
          type='text' name='firstname' placeholder='FIRST NAME'/>
        <input className={ln}
          value={this.state.lastName}
          onChange={(e) => { this.setState({ lastName: e.target.value}) }}
          type='text' name='lastName' placeholder='LAST NAME'/>
        <input className={email}
          value={this.state.email}
          onChange={(e) => { this.setState({ email: e.target.value}) }}
          type='text' name='email' placeholder='EMAIL'/>
        <input className={city}
          value={this.state.city}
          onChange={(e) => { this.setState({ city: e.target.value}) }}
          type='text' name='city' placeholder='CITY'/>
        <input className={addr}
          value={this.state.address}
          onChange={(e) => { this.setState({ address: e.target.value}) }}
          type='text' name='address' placeholder='ADDRESS'/>
        <input className={phone}
          value={this.state.phone}
          onChange={(e) => { this.setState({ phone: e.target.value}) }}
          type='text' name='phone' placeholder='PHONE'/>
        <input className='input'
          value={this.state.discountCode}
          onChange={(e) => { this.onDiscountChange(e.target.value) }}
          type='text' name='discount' placeholder='DISCOUNT CODE'/>
        <div className='checkout-btn'>
          <Button onClick={() => { this.onLocalCheckout() }} value={'CHECKOUT'}/>
        </div>
      </div>
    )
  }

  render() {
    const currency = (this.props.products[0] || {}).currency
    let price = this.getTotal()
    const priceStyle = price > 0 ? 'cart-total-price' : 'cart-total-price-invalid';

    return (
      <div>
        <h2 className={priceStyle}>Total: {price} {currency}</h2>
        { this.state.shipping === UNKNOWN ?
            this.shippingDestination() :
              this.state.shipping === LOCAL ?
                this.shippingLocal() : null }
      </div>
    )
  }
}

export default CartShipping
