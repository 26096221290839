import React, { Component } from 'react'
import Aside from '../components/Aside'
import Navigation from '../components/Navigation'
import Content from '../components/Content'
import Footer from '../components/Footer'
import { Link } from 'react-router-dom'

import './PurchaseScreen.css'


class PurchaseScreen extends Component {
  render() {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    return (
      <div>
        <Aside>
          <Navigation/>
        </Aside>
        <Content>
          <div>
            <p className='purchase-text'>
              THANK YOU FOR YOUR PURCHASE.
              CHECKOUT YOUR EMAIL FOR CONFIRMATION
            </p>
            <Link className='purchase-shop-link uk-link-text' to='/shop'>
              BACK TO SHOP
            </Link>
          </div>
        </Content>
        <Footer/>
      </div>
    );
  }
}

export default PurchaseScreen
