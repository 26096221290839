import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import './ProductThumb.css'


class ProductThumb extends Component {
  render() {
    return (
      <div className='product-thumb'>
        <Link to={this.props.link}>
          <img className='product-thumb-img' src={this.props.image} alt={this.props.name}/>
        </Link>
      </div>
    )
  }
}

export default ProductThumb
