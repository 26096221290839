import React, { Component } from 'react';
import "./PrivacyPolicy.css"

export default class PrivacyPolicy extends Component {
  render() {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    return (
      <div className="padding-left">
        <h2 className="section-title">privacy policy</h2>
        <div>
          <h2 className="section-title">Политика за поверителност</h2>
        </div>
        <hr/>
        <br/>
        <div className="section-text-left">
          <div className="privacy-policy">
            <div>
              <strong>Вашата лична информация</strong>
            </div>
            <hr/>
            <div>
              Тази политика за защита на личните данни обяснява как събираме и обработваме Вашите лични данни. Лични данни означава всяка информация или набор от информация, която идентифицира или би могла да бъде използвана за Вашето идентифициране (заедно с друга информация). Това включва информация, която споделяте с нас, какво научаваме от Вас, както и избора, който правите относно маркетинговите съобщения, който искате да изпратим до Вас. Тази политика обяснява как обработваме Вашите лични данни, какви са Вашите права и как законът Ви защитава.

              Съзнателно не събираме данни, свързани с деца.
            </div>
            <br/>

            <div>
              <strong>Начини по които събираме Вашите лични данни</strong>
            </div>
            <hr/>
            <div>
              Ние може да събираме лична информация за Вас по следните начини:
              <ul>
                <li>Данни, които самите Вие споделяте с нас:</li>
                <li>Данни, които споделяте с нас, когато говорите с нас по телефона</li>
                <li>Данни, които ни изпращате в имейли или писма до нас</li>
                <li>Данни във връзка с Ваше участие в наши промоции или събития</li>
                <li>Данни, които изпращате към нас под формата на обратна връзка</li>
              </ul>
            </div>
            <br/>

            <div>
              <strong>Данни който събираме и цели за които ги използваме</strong>
            </div>
            <hr/>
            <div>
              <div>
                 Когато направите поръчка през сайта shop.kypbn.com ние Ви молим за Вашите данни, който използваме
                 единствено с цел доставка на продукта който сте поръчали. Ето и данните който използваме за доставка:
              </div>

              1. Доставски в рамките на България - Информацията за поръчките и доставките се предоставя на Еконт Експрес
                 с цел доставяне на поръчаните продукти от shop.kypbn.com. Ако имате нужда от повече информация относно,
                 как те използват данните Ви, можете да проверите
                 <a className="underlined-link" href="https://www.econt.com/">тук</a>
              <ul>
                <li>Име</li>
                <li>Фамилия</li>
                <li>Електронна поща (email)</li>
                <li>Телефон</li>
                <li>Град</li>
                <li>Адрес</li>
              </ul>

              3. Данни при записване за допълнителна информация относно нашите продукти и колекции - ние маркираме,
                 че сте съгласни да получавате електронни съобщение под формата на email, относно нашите
                 продукти и колекции.

              <div>
                Посочените данни се пазят в нашите бази данни (Postgresql), който имат няколко нива на сигурност
                за да осигурим защита на личните Ви данни. Данните са пазят в срок от 5 години. След това автоматично се изтриват.
                Вие като наш клиент, винаги можете да изтриете данните, които пазим за Вас по всяко време.
                За целта, може да се свържете с нас на
                <a className="underlined-link" href="mailto:kypbn.eastern@gmail.com?Subject='Delete my data'" target="_top">kypbn.eastern@gmail.com</a>
              </div>
            </div>
            <br/>

            <div>
              <strong>Непредоставяне на лични данни</strong>
            </div>
            <hr/>
            <div>
              В случаите, когато трябва да обработваме Ваши лични данни във връзка със законово изискване
              или съгласно условията на договор, който имаме с Вас, и не можете да предоставите тези данни при поискване,
              може да не успеем да изпълним договора, който имаме или се опитваме да сключим с Вас
              (например да Ви предоставим дадена услуга). В този случай може да се наложи да не предоставим услугата,
              която желаете от нас, но ние ще Ви уведомим, ако това е така.
            </div>
            <br/>

            <hr/>
            <div>
              Когато посещавате уебсайт, можете да съхраните или извлечете информация на своя браузър най-често под формата на „бисквитки“.
              Тази информация, която може да касае Вас, Вашите предпочитания или Вашето устройство за достъп до интернет
              (компютър, таблет или мобилен телефон) най-често се използва, за да работи уебсайтът така, както очаквате Вие.
              Обикновено информацията не може да бъде свързана лично с Вас, но може да бъде използвана за по-персонализирано
              използване на световната мрежа.
            </div>
            <br/>
            <div>
              Тъй като зачитаме правото Ви на защита на лична информация, можете да изберете да не допускате употреба
              на всички или на някои типове бисквитки. Ако постъпите така обаче, това може да се отрази на ползването
              на сайта и на услугите, които можем да Ви предложим.
            </div>
            <br/>
            <div>
              Услуги на трети страни, използвани от настоящия уебсайт, могат да залагат различни бисквитки при посещение на сайта.
              Тези сайтове и услуги могат да имат различна политика относно бисквитките. Използваните от настоящия сайт
              услуги на трети страни включват: Google.com, Instagram.com, Facebook.com.
            </div>
            <br/>
            <br/>

            <div>
              <strong>Вашите права</strong>
            </div>
            <hr/>
            <div>
              Общият регламент за защита на лични данни Ви гарантира определен набор от права,
              които може да упражните във връзка с обработваните от нас Ваши лични данни. По-конкретно Вие имате:
              <br/>
              <br/>
              <ul>
                <li>
                  Право на получаване на копие от информацията, която съхраняваме - Можете да ни поискате копие от личните данни,
                  които съхраняваме за Вас, като ни изпратите имейл на адрес
                  <a className="underlined-link" href="mailto:kypbn.eastern@gmail.com" target="_top">kypbn.eastern@gmail.com</a>.
                  Ще се опитаме да отговорим на всички легитимни искания в рамките на един месец.
                  Понякога може да ни отнеме повече от месец, в който случай ще Ви уведомим.
                </li>
                <br/>

                <li>
                  Право да ни кажете дали информацията, която съхраняваме, е неправилна -
                  Имате право да поставяте под въпрос всяка информация, която съхраняваме за Вас,
                  която смятате за грешна или непълна. Моля, свържете се с нас по електронната поща на адрес
                  <a className="underlined-link" href="mailto:kypbn.eastern@gmail.com" target="_top">kypbn.eastern@gmail.com</a>
                  ако искате да направите това ние и ще предприемем разумни стъпки,
                  за да проверим точността на информацията и, ако е необходимо, да я коригираме.
                </li>
                <br/>

                <li>
                  Да ни кажете, че искате да преустановим използването на Вашите лични данни - Имате право да:
                  <ul>
                    <li>Възразите срещу използването на Вашите лични данни</li>
                    <li>Поискайте да изтрием личните Ви данни</li>
                    <li>Да поиска ограничаване на обработката</li>
                    <li>Да ни помолите да спрем да ги използваме, ако няма нужда да го правим (известно като правото да бъдеш забравен)</li>
                  </ul>
                </li>
                <br/>

                <li>
                  Възможно е да има правни основания, поради които трябва да запазим или използваме Вашите данни,
                  които ние ще споделим с Вас, когато упражнявате някое от гореизброените права.
                </li>
                <br/>

                <li>
                  Оттегляне на съгласието - Можете да оттеглите Вашето съгласие за това да използваме Вашите лични данни по всяко време.
                  Моля, свържете се с нас на имейл
                  <a className="underlined-link" href="mailto:kypbn.eastern@gmail.com" target="_top">kypbn.eastern@gmail.com</a>,
                  ако искате да оттеглите Вашето съгласие. Ако оттеглите съгласието си, може да не сме в състояние да Ви
                  предоставим определени продукти или услуги.
                </li>
              </ul>
            </div>
            <br/>

            <div>
              <strong>Подаване на жалба</strong>
            </div>
            <hr/>
            <div>
              Моля, уведомете ни, ако не сте доволни от начина, по който сме използвали личните Ви данни, като ни изпратите имейл на <a className="underlined-link" href="mailto:kypbn.eastern@gmail.com" target="_top">kypbn.eastern@gmail.com</a>
            </div>
            <br/>
            <div>
              Имате право да се оплачете и в българската Комисия за защита на личните данни, с адрес: България, 1592, гр.София, бул. „Проф. Цветан Лазаров“ №2; Телефон: +359 2 91 53 518; Факс: +359 2 91 53 525; адрес на електронна поща: kzld@cpdp.bg, website: https://www.cpdp.bg/.
            </div>
            <br/>

            <div>
              <strong>Задължителни бисквитки за функционирането на сайта</strong>
            </div>
            <hr/>
            <div>
              Тези бисквитки са необходими за функциониране на уебсайта и не могат да бъдат изключени в нашите системи. Те обикновено се залагат в отговор на действия, извършени от Вас, които представляват заявка за услуги, като например настройки на предпочитанията Ви за защита на личната Ви информация, вход в сайта или попълване на формуляри.
            </div>
            <br/>
            <div>
              Можете да настроите своя браузър да блокира или да Ви уведомява за тези бисквитки, но в такъв случай някои части от сайта няма да работят добре. Тези бисквитки не съхраняват информация, която може да бъде свързана лично с Вас.
            </div>
            <br/>
            <br/>
            <div>
              <strong>Задължителни бисквитки</strong>
              <ul>
                <li>cookieconsent_status</li>
              </ul>
            </div>
            <br/>
            <br/>

            <div>
              <strong>Бисквитки за резултатност</strong>
            </div>
            <hr/>
            <div>
              Тези бисквитки ни позволяват да преброим посещенията и източниците на трафик, така че да сме в състояние да измерим и подобрим резултатността на нашия сайт. Те ни помагат да разберем кои страници са най-популярни и кои – най-непопулярни, както и да видим как посетителите се движат в сайта.
            </div>
            <br/>
            <div>
              За предоставяне на тази услуга използваме Google Analytics и Facebook бисквитки от първа страна. Събраните данни не се предоставят на никоя друга страна. Благодарение на ремаркетинг бисквитките на Google Analytics и Facebook ще можем да Ви показваме информация за артикулите ни навсякъде в мрежата на Google и Facebook. Съхраняваме личните Ви данни в Google в срок не по-дълъг от 18 месеца.
            </div>
            <br/>
            <div>
              Информацията, която получаваме благодарение използването на тези бисквитки, е анонимна и не правим опит да Ви идентифицираме или да повлияем на начина Ви на използване на сайта, когато го посетите. Ако не разрешите тези бисквитки, няма да бъдем в състояние да включим посещението Ви в нашата статистика.
            </div>
            <br/>
            <div>
              Ако използвате настройка на браузъра  „Do Not Track“, приемаме това за сигнал, че не желаете да разрешите тези бисквитки и те ще бъдат блокирани.
            </div>
          </div>
        </div>
      </div>
    )
  }
}
