import React, { Component } from 'react'
import './ImageGallery.css'
import { Link } from 'react-router-dom'


class ImageGallery extends Component {
  render() {
    const images = this.props.images.map((img, i) => {
      const imgComponent = (
        <div className='image-gallery-wrapper' key={i}>
          <img className='image-gallery-img' data-src={img} alt='Collection' data-uk-img/>
        </div>
      )

      if (this.props.link) {
        return (
          <Link to={this.props.link} key={i}>{imgComponent}</Link>
        )
      }

      return imgComponent
    })

    return (
      <div className='image-gallery'>
        {images}
        <div className='image-gallery-link-wrapper'>
          {this.props.link ?
            <Link className='image-gallery-link uk-link-text' to={this.props.link}>
              {this.props.linkName}
            </Link> : null
          }
        </div>
      </div>
    )
  }
}

export default ImageGallery
