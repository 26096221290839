import React, { Component } from 'react'
import Logo from './Logo'

import './Aside.css'

class Aside extends Component {
  render() {
    return (
      <div className='col-3 aside-wrapper'>
        <Logo/>
        {this.props.children}
      </div>
    )
  }
}

export default Aside
