import React, { Component } from 'react'
import './Content.css'
import config from '../config/Config'
import { Link } from 'react-router-dom'


class Content extends Component {
  render() {
    const cartButton = `${config.ApiURL}/static/media/images/shopping-cart.png`
    return (
      <main className='col-9 content-wrapper'>
        <div className='mobile-cart-wrapper'>
          <Link to='/cart'>
            <img
              className='mobile-cart'
              src={cartButton}
              alt='cart button'
            />
          </Link>
        </div>
        {this.props.children}
      </main>
    )
  }
}

export default Content
