import React, { Component } from 'react'
import './Footer.css'
import config from '../config/Config'


class Footer extends Component {
  render() {
    const footerLinks = [
      {
        name: 'Terms and Conditions',
        link: '/terms-and-conditions',
        target: '_blank',
      },
      {
        name: 'Privacy Policy',
        link: '/privacy-policy',
        target: '_blank',
      },
      {
        name: 'Facebook',
        link: config.FacebookLink,
        target: '_blank',
      },
      {
        name: 'Instagram',
        link: config.InstagramLink,
        target: '_blank',
      },
      {
        name: 'Delivery Information',
        link: '/delivery',
      },
      {
        name: 'Email us',
        link: `mailto:${config.Email}`,
      },
    ].map((item, i) => {
      return (
        <li key={i} >
          <a target={item.target} href={item.link}>{item.name}</a>
        </li>
      )
    })

    return (
      <div className='footer'>
        <ul>
          {footerLinks}
        </ul>
        <span className='copyright'>Copyright ⓒ  2017 EASTERN EUROPE'S FINEST / KYPBN. All Rights Reserved</span>
      </div>
    );
  }
}

export default Footer
