import React, { Component } from 'react';
import './Logo.css';

class Logo extends Component {
  render() {
    return (
      <a className='uk-link-text' href='/'>
        <div className='uk-margin-large-bottom logo'>
          EASTERN<br/>
          EUROPE'S<br/>
          FINEST<br/>
        </div>
      </a>
    )
  }
}

export default Logo
