import React, { Component } from 'react'
import './CartThumb.css'
import config from '../config/Config'

class CartThumb extends Component {
  render() {
    const { name, size, price, qty, currency, image } = this.props.product
    const subtotal = price * qty
    const removeButton = `${config.ApiURL}/static/media/images/cancel.png`

    return (
      <div className='cart-thumb'>
        <img
          className='cart-remove-button'
          onClick={() => {this.props.onRemove()}}
          src={removeButton}
          alt='remove button'
        />
        <img className='cart-thumb-image'
          src={image} alt=''/>
        <div className='cart-thumb-info'>
          <div className='cart-thumb-header'>
            <strong>EASTER EUROPE'S FINEST<br/>
              {name}
            </strong>
          </div>
          <div>
            <div className='cart-thumb-text'>
              <strong>Size</strong>: {size}
            </div>
            <div className='cart-thumb-text'>
              <strong>Price</strong>: {subtotal} {currency}
            </div>
            <div className='cart-thumb-text'>
              <strong>Quantity</strong>: {qty}
            </div>
          </div>
        </div>
        <div className='clear-both'></div>
        <hr className='cart-thumb-sep'/>
      </div>
    )
  }
}

export default CartThumb
