import React, { Component } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import './App.css'
import InitialScreen from './screens/InitialScreen';
import ArchiveScreen from './screens/ArchiveScreen';
import ProductScreen from './screens/ProductScreen';
import ShopScreen from './screens/ShopScreen';
import PurchaseScreen from './screens/PurchaseScreen';
import NotFoundScreen from './screens/NotFoundScreen';
import LookbookScreen from './screens/LookbookScreen';
import PrivacyPolicy from './screens/PrivacyPolicy';
import TermsScreen from './screens/TermsScreen';
import DeliveryScreen from './screens/DeliveryScreen';
import CartScreen from './screens/CartScreen';
import client from './client/client'
import cart from './cart/Cart'

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  async componentDidMount() {
    const resp = await client.get('/collections')
    this.setState({ collections: resp.collections })
  }

  render() {
    if (!this.state.collections) {
      return (
        <div>Loading</div>
      )
    }

    const { collections } = this.state

    return (
      <BrowserRouter className='app'>
        <Switch>
          <Route exact path='/' render={(props) => {
            return (
              <InitialScreen {...props} collections={collections}/>
            )
          }}/>
          <Route exact path='/shop' render={(props) => {
            return (
              <ShopScreen {...props} collections={collections}/>
            )
          }}/>
          <Route exact path='/cart' render={(props) => {
            return (
              <CartScreen {...props} cart={cart}/>
            )
          }}/>
          <Route exact path='/archive' render={(props) => {
            return (
              <ArchiveScreen {...props} collections={collections}/>
            )
          }}/>
          <Route exact path='/lookbook/:collectionId' render={(props) => {
            const c = collections.find(c => c.id === parseInt(props.match.params.collectionId))
            return (
              <LookbookScreen {...props} collection={c}/>
            )
          }}/>
          <Route exact path='/shop/collection/:collectionId' render={(props) => {
            const collection = collections.find(c => c.id === parseInt(props.match.params.collectionId))
            return (
              <ShopScreen {...props} collections={[collection]}/>
            )
          }}/>
          <Route exact path='/product/:productId' render={(props) => {
            const product = [].concat.apply([], collections
              .map(c => c.products))
              .find((p) => p.id === parseInt(props.match.params.productId)
            )

            return (
              <ProductScreen {...props} product={product} cart={cart}/>
            )
          }}/>
          <Route exact path='/purchase' component={PurchaseScreen}/>
          <Route exact path='/privacy-policy' component={PrivacyPolicy}/>
          <Route exact path='/terms-and-conditions' component={TermsScreen}/>
          <Route exact path='/delivery' component={DeliveryScreen}/>
          <Route component={NotFoundScreen}/>
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
