import React, { Component } from 'react';
import './Button.css';

class Newsletter extends Component {
  render() {
    const onClick = () => {
      if (this.props.onClick) {
        this.props.onClick()
      } else {
        console.log('Button without onclick handler')
      }
    }

    return (
      <button
        className={'uk-button uk-button-default action-button ' +
          this.props.className}
        onClick={onClick}
        disabled={this.props.disabled}
      >
        { this.props.value }
      </button>
    )
  }
}

export default Newsletter
