import React, { Component } from 'react'
import config from '../config/Config'
import client from '../client/client'
import { Link } from 'react-router-dom'

import './Navigation.css'

class Navigation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      newsletter: '',
      newsletterPlaceholder: 'NEWSLETTER',
    }
  }

  async newsletterSubmit() {
    try {
      const resp = await client.post('/newsletter', {
        'email': this.state.newsletter,
      })

      if (resp.ok) {
        this.setState({ newsletter: 'SUBMITTED' })
        setTimeout(() => {
          this.setState({ newsletter: '' })
        }, 500)
      } else {
        this.setState({ newsletter: 'ERROR' })
        setTimeout(() => {
          this.setState({ newsletter: '' })
        }, 500)
      }
    } catch (e) {
      console.error(e)
      this.setState({ newsletter: 'ERROR' })
      setTimeout(() => {
        this.setState({ newsletter: '' })
      }, 500)
    }
  }

  render() {
    const links = [
      { name: 'shop', link: '/shop', local: true },
      { name: 'cart', link: '/cart', local: true },
      { name: 'archive', link: '/archive', local: true },
      { name: 'instagram', link: config.InstagramLink },
      { name: 'facebook', link: config.FacebookLink },
    ];
    const navLinks = links.map((link, i) => {
      let content = null
      if (link.local) {
        content = (
          <Link className="uk-link-text" to={`${link.link}`}>
            {link.name.toUpperCase()}
          </Link>
        )
      } else {
        content = (
          <a target='_blank' rel='noopener noreferrer' className="uk-link-text" href={`${link.link}`}>
            {link.name.toUpperCase()}
          </a>
        )
      }
      return (
        <li key={i} className="nav-link">
          {content}
        </li>
      )
    })

    return (
      <div className='col-3 nav-wrapper'>
        <div className='nav'>
          <ul className='nav-links'>
            {navLinks}
          </ul>
          <input className='nav-newsletter'
            value={this.state.newsletter}
            onChange={(e) => { this.setState({ newsletter: e.target.value}) }}
            onFocus={() =>  { this.setState({ newsletterPlaceholder: '' }) }}
            onBlur={() => { this.setState({ newsletterPlaceholder: 'NEWSLETTER' }) }}
            onKeyDown={(e) => { if(e.key === 'Enter') { this.newsletterSubmit() }}}
            type='text' name='newsletter' placeholder={this.state.newsletterPlaceholder}/>
        </div>
      </div>
    )
  }
}

export default Navigation
