import React, { Component } from 'react'
import Aside from '../components/Aside'
import Content from '../components/Content'
import Navigation from '../components/Navigation'
import Footer from '../components/Footer'
import { Link } from 'react-router-dom'

import './ArchiveScreen.css'


class ArchiveScreen extends Component {
  render() {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    const collectionLinks = this.props.collections.map((c) => {
      return (
        <li key={c.id}>
          <Link className='archive-link uk-link-text' to={`/lookbook/${c.id}`}>
            {c.name}
          </Link>
        </li>
      )
    })

    return (
      <div>
        <Aside>
          <Navigation/>
        </Aside>
        <Content>
          <div className='archive-wrapper'>
            <h1 className='archive-title'>ARCHIVE</h1>
            <ul className='archive-list'>
              {collectionLinks}
            </ul>
          </div>
        </Content>
        <Footer/>
      </div>
    );
  }
}

export default ArchiveScreen
