import React, { Component } from 'react'
import Button from './Button'

import './ProductInfo.css'

class ProductInfo extends Component {
  constructor(props) {
    super(props)

    this.state = {
      size: '',
      checkoutBtn: 'ADD TO CART',
    }
  }

  addToCart() {
    this.setState({checkoutBtn: 'ADDED'})

    setTimeout(() => {
      this.setState({checkoutBtn: 'ADD TO CART'})
    }, 500)

    if (this.props.onAddToCart !== null) {
      this.props.onAddToCart(this.state.size)
    }
  }

  productActions() {
    const productSizes = (this.props.sizes).map((size, i) => {
      let style = 'product-size'
      if (size === this.state.size) {
        style += ' product-size-selected'
      }
      return (
        <li key={i} className={style} onClick={() => { this.setState({size: size}) }}>{size}</li>
      )
    })

    return (
      <div>
        <a target='_blank' rel='noopener noreferrer' className='uk-link-text product-size-chart' href={this.props.sizeChart}>SIZE CHART</a>
        <ul className='product-sizes'>
          {productSizes}
        </ul>
        <div className='clear-both'></div>
        <Button
          disabled={this.state.size === ''}
          className='product-add-to-cart'
          onClick={() => { this.addToCart() }}
          value={this.state.checkoutBtn}
        />
      </div>
    )
  }

  render() {
    let price = null;
    if (this.props.discount_price) {
      price = <h2 className='product-price'>Price: <strike>{this.props.price} {this.props.currency}</strike> {this.props.discount_price} {this.props.currency}</h2>
    } else {
      price = <h2 className='product-price'>Price: {this.props.price} {this.props.currency}</h2>
    }

    return (
      <div className='product-info'>
        <h1 className='product-name'>
          {this.props.name}
        </h1>
        <div className='product-desc'
          dangerouslySetInnerHTML={{__html: this.props.description}}/>
        {price}
        { this.props.sizes.length > 0 ?
            this.productActions() :
            <p className='product-sold-out'>THIS PRODUCT IS SOLD OUT!</p>
        }
      </div>
    )
  }
}

export default ProductInfo
