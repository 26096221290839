import React, { Component } from 'react'
import Aside from '../components/Aside'
import Navigation from '../components/Navigation'
import Content from '../components/Content'
import ImageGallery from '../components/ImageGallery'
import './LookbookScreen.css'


class LookbookScreen extends Component {
  render() {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    const { lookbook } = this.props.collection
    return (
      <div>
        <Aside>
          <Navigation/>
        </Aside>
        <Content>
          <ImageGallery
            images={lookbook}
            link={`/shop/collection/${this.props.collection.id}`}
            linkName={`${this.props.collection.name} PRODUCTS`}
          />
        </Content>
      </div>
    )
  }
}

export default LookbookScreen
