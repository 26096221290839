import React, { Component } from 'react'
import Aside from '../components/Aside'
import Content from '../components/Content'
import Navigation from '../components/Navigation'
import Footer from '../components/Footer'
import ImageGallery from '../components/ImageGallery'

class InitialScreen extends Component {
  render() {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    const previews = (this.props.collections || []).map((c) => {
      if (c.lookbook.length > 0) {
        const collectionLink = `/lookbook/${c.id}`
        return (
          <ImageGallery
            key={c.id}
            images={c.lookbook.slice(0, 2)}
            link={collectionLink}
            linkName={c.name}
          />
        )
      }

      return null
    })

    return (
      <div>
        <Aside>
          <Navigation/>
        </Aside>
        <Content>
          {previews}
        </Content>
        <Footer/>
      </div>
    );
  }
}

export default InitialScreen
