import React, { Component } from 'react'
import Aside from '../components/Aside'
import Content from '../components/Content'
import Navigation from '../components/Navigation'
import Footer from '../components/Footer'
import ProductThumb from '../components/ProductThumb'

class ShopScreen extends Component {
  render() {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    const { collections } = this.props
    const sorted_collections = [...collections].sort((first, second) => second.created_at - first.created_at)

    let products = sorted_collections.map((c) => {
      return c.products.map((p) => {
        return p
      })
    })
    products = products.flat()
    products.sort((first, second) => second.sizes_qtys.length - first.sizes_qtys.length)
//     products.sort((first, second) => first.order - second.order)

    const productThumbs = products.map((p) => {
        return (
          <ProductThumb
            key={p.id}
            image={p.images[0]}
            link={`/product/${p.id}`}
            name={p.name}
          />
        )
    })

    return (
      <div>
        <Aside>
          <Navigation/>
        </Aside>
        <Content>
          {productThumbs}
        </Content>
        <Footer/>
      </div>
    );
  }
}

export default ShopScreen
