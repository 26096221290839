import config from '../config/Config'

class Client {
  constructor(apiURL) {
    this.apiURL = apiURL
  }

  async get(uri) {
    return await (await fetch(`${this.apiURL}/api${uri}`)).json()
  }

  async post(uri, data) {
    return await (await fetch(`${this.apiURL}/api${uri}`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }))
  }
}

var client = new Client(config.ApiURL)
export default client
