import React, { Component } from 'react'
import Aside from '../components/Aside'
import Content from '../components/Content'
import Button from '../components/Button'
import CartShipping from '../components/CartShipping'
import CartThumb from '../components/CartThumb'
import Footer from '../components/Footer'
import LoadingOverlay from 'react-loading-overlay';

import './CartScreen.css'


class CartScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      products: this.props.cart.products,
      loader: false,
    }
  }

  onRemove(product) {
    this.props.cart.remove(product)
    this.setState({products: this.props.cart.products})
  }

  toggleLoader() {
    this.setState({loader: !this.state.loader})
  }

  render() {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    const cartProducts = this.state.products.map((p, i) => {
      return (
        <CartThumb key={i}
          onRemove={() => {this.onRemove(p)}}
          product={p}
        />
      )
    })

    const content = (
      <div>
        <Aside>
          <CartShipping
            cart={this.props.cart}
            products={this.state.products}
            toggleLoader={() => {this.toggleLoader()}}
          />
          <Button
            className='back-to-shop-btn'
            value='BACK TO SHOP'
            onClick={() => {
              this.props.history.push('/shop');
            }}
          />
        </Aside>
        <Content>
          {cartProducts}
        </Content>
        <Footer/>
      </div>
    )

    if (this.state.loader === true) {
      return (
        <LoadingOverlay
          className='content-loader'
          active={true}
          spinner
          text='Loading...'
        >
          {content}
        </LoadingOverlay>
      )
    } else {
      return content
    }
  }
}

export default CartScreen
