import React from 'react'


const NotFoundScreen = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });

  return (
    <div>Page not Found</div>
  )
}

export default NotFoundScreen
