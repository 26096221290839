import React, { Component } from 'react'
import Footer from '../components/Footer'
import Aside from '../components/Aside'
import ProductInfo from '../components/ProductInfo'
import Content from '../components/Content'
import Button from '../components/Button'
import ImageGallery from '../components/ImageGallery'

class ProductScreen extends Component {
  _onAddToCart(size) {
    const { product } = this.props
    const price = product.discount_price ? product.discount_price : product.price

    const cartProduct = {
      id: product.id,
      name: product.name,
      size: size,
      price: price,
      qty: 1,
      currency: product.currency,
      image: product.images[0],
    }

    let { cart } = this.props
    cart.add(cartProduct)
    console.log('added to cart')
  }

  render() {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    const {
      name,
      price,
      discount_price,
      currency,
      description,
      size_chart,
      sizes_qtys,
      images,
    } = this.props.product

    const sizes = sizes_qtys.map(s => s.size)
    return (
      <div>
        <Aside>
          <ProductInfo
            name={name}
            price={price}
            discount_price={discount_price}
            currency={currency}
            description={description}
            sizeChart={size_chart}
            sizes={sizes}
            onAddToCart={(size) => { this._onAddToCart(size) }}
          />
          <Button
            className='back-to-shop-btn'
            value='BACK TO SHOP'
            onClick={() => {
              this.props.history.push('/shop')
            }}
          />
        </Aside>
        <Content>
          <ImageGallery
            images={images}
          />
        </Content>
        <Footer/>
      </div>
    );
  }
}

export default ProductScreen
