const configProvider = process.env

const config = {
  InstagramLink: 'https://www.instagram.com/eastern.europes.finest/',
  FacebookLink: 'https://www.facebook.com/EASTERN.EUROPES.FINEST/',
  Email: 'kypbn.easter@gmail.com',
  ApiURL: configProvider.REACT_APP_API_URL,
  SentryDSN: 'https://be8734164938403bbb59bebadb3aea88@sentry.io/1493677',
};

export default config
