const CART_KEY = 'EEF_CART'

class Cart {
  constructor(storage) {
    this.storage = storage
    this.products = this.storage.load(CART_KEY)

    // Initialize if not exists
    if (this.products == null) {
      this.products = []
      this.storage.save(CART_KEY, this.products)
    }
  }

  add(product) {
    this.products.push(product)
    this.storage.save(CART_KEY, this.products)
  }

  remove(product) {
    console.log('Remove product', product)
    this.products = this.products.filter((p) => {
      return product.id !== p.id || product.size !== p.size
    })

    console.log('Filtered products', this.products)
    this.storage.save(CART_KEY, this.products)
  }

  clear() {
    this.storage.save(CART_KEY, []);
  }
}

class LocalStorage {
  save(key, val) {
    localStorage.setItem(key, JSON.stringify(val))
  }

  load(key) {
    return JSON.parse(localStorage.getItem(key))
  }
}

let cart = new Cart(new LocalStorage())
export default cart;
